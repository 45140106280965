import React, { useEffect, createContext } from 'react';
import Logo from '../header/Logo';
import Header from '../header/Header';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCheckoutForm from '../stripe/MyCheckoutForm';
import API from '../../config/apis/APIs';
import { CA_STRIPE_KEY, TX_STRIPE_KEY } from '../../config/stripe/PublishableKeys';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';

dayjs.extend(isSameOrAfter);
// TODO: This should likely be moved to App to prevent re-renders
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromiseCA = loadStripe(CA_STRIPE_KEY);
const stripePromiseTX = loadStripe(TX_STRIPE_KEY);

const getChargesText = (url = '') => {
  const lastPart = url.split('/').pop();

  if (lastPart === 'eval') {
    return {
      chargeText: `After completing your payment information, you’ll be charged $75 for the initial evaluation. You'll be charged for future sessions on the day of each appointment.`,
      cancelText: `If you need to cancel or reschedule an appointment, we ask for a 12 hour notice to be eligible to reschedule at a later date.`,
    };
  }

  return {
    chargeText: `After completing your payment information, you’ll be charged $59 for the initial evaluation. Your weekly
              subscription will then begin automatically for your next scheduled session, and you'll be charged at the
              same time each week moving forward.`,
    cancelText: ` If you need to cancel or reschedule an appointment, we ask for a 12 hour notice to be eligible to
              reschedule at a later date. Because it’s a subscription, the charge will still occur on its usual weekly
              day, but we will provide a make up session.`,
  };
};

export const BillingInformationContext = createContext({});

function Billing({ match }) {
  const {
    params: { customerid },
    url,
  } = match;

  let history = useHistory();

  if (url.includes('evalmonthly')) {
    history.push(`/${customerid ?? 'error'}/contact-support?error=evalmonthly`);
  }

  const { status, data, error } = useQuery('apiBilling', () =>
    axios.get(`${API}/billing-information/${customerid}`).then(res => res.data.body),
  );


  useEffect(() => {
    // errors
    if (data && data.Item.evaluationDate) {
      const isEvaluationDateSameOrAfterNow = dayjs(data.Item.evaluationDate).isSameOrAfter(dayjs(), 'day');

      // If evaluation date is not the same or after now then redirect to contact support
      if (!isEvaluationDateSameOrAfterNow) {
        history.push(`/${customerid}/contact-support`);
      }
    }
    // If there is no evaluation date, billing will crash

    if (data && !data.Item.evaluationDate) {
      history.push(`/${customerid}/contact-support`);
    }

    //Links generated from health record
    if (url.includes('ehr/')) {
      if (data && data.Item.paid === true && data.Item.intake === 'adult') {
        history.push(`/${customerid}/ehr/adult`);
      }
      if (data && data.Item.paid === true && data.Item.intake === 'child') {
        history.push(`/${customerid}/ehr/child`);
      }
      if (data && data.Item.paid === true && data.Item.intake === null) {
        history.push(`/${customerid}/contact-support`);
      }
    } else {
      // If user has already paid, prevent them from paying again
      if (data && data.Item.paid === true) {
        history.push(`/${customerid}/thank-you`);
      }
    }

    // Silences a react warning
    return;
  });

  return (
    <BillingInformationContext.Provider value={data}>
      <BillingForm status={status} data={data} error={error} match={match} url={url} />
    </BillingInformationContext.Provider>
  );
}

const BillingForm = ({ status, data, error, match, url }) => {
  const isPaymentMethodEval = url.includes('eval');
  const chargesText = getChargesText(url);
  return (
    <>
      <Logo />
      <Header title="Billing" />

      {/* TODO: Put in loading icon here */}
      {/* {status === 'loading' && 
        <div>I'm loading...</div>
      } */}

      {status === 'error' && <div>Error: {error.message}</div>}

      {status === 'success' && (
        <>
          <section className="max-w-md mx-auto mt-16 px-4">
            <h2 className="text-3xl font-bold text-purple-700 flex justify-center">{isPaymentMethodEval ? '$75.00' : '$59.00'}</h2>
            {/* Removes the time portion which causes the date to be incorrectly shown typically on pacific time zones */}
            {/* This section only shows if there is an eval date */}
            {data.Item.evaluationDate && (
              <p className="mb-8 text-md text-gray-700 flex justify-center">{`First evaluation session on ${dayjs(
                data.Item.evaluationDate.split('T')[0],
              )
                .format('MMMM D')
                .toString()}`}</p>
            )}
          </section>

          {data.Item.stripeAccount === 'Expressable SLP (CA)' && (
            <Elements stripe={stripePromiseCA} className="max-w-md mx-auto px-4">
              <InjectedCheckoutForm
                className="border-red-500"
                match={match}
                evaluationDate={data.Item.evaluationDate}
                stripeAccount={data.Item.stripeAccount}
              />
            </Elements>
          )}

          {data.Item.stripeAccount !== 'Expressable SLP (CA)' && (
            <Elements stripe={stripePromiseTX} className="max-w-md mx-auto px-4">
              <InjectedCheckoutForm
                className="border-red-500"
                match={match}
                evaluationDate={data.Item.evaluationDate}
                stripeAccount={data.Item.stripeAccount}
              />
            </Elements>
          )}

          <section>
            <p className="text-xs max-w-md mx-auto px-4 text-gray-600 mt-4">{chargesText.chargeText}</p>
            <p className="text-xs max-w-md mx-auto px-4 text-gray-600 mt-4 mb-12">{chargesText.cancelText}</p>
          </section>
        </>
      )}
    </>
  );
};

export default Billing;
