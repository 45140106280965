import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryString } from '../utilities/useQuery';
import API from '../../config/apis/APIs';
import { BillingInformationContext } from '../pages/Billing';

import { CardElement, ElementsConsumer, useStripe, useElements } from '@stripe/react-stripe-js';

import '../../common.css';

const getBillingTypeBasedFromUrl = url => {
  const urlLastChunk = url.split('/').pop(); // 'eval', 'deposit' or '' (legacy billing)

  switch (urlLastChunk) {
    case 'eval':
      return 'eval_only';
    case 'deposit':
      return 'deposit_only'; // only in from Cue soon
    default:
      return ''; // legacy billing
  }
};

function CardError({ error }) {
  return <div className="mb-4 text-sm text-red-700">{error}</div>;
}

const CheckoutForm = ({ match, stripeAccount }) => {
  const {
    params: { customerid },
    url,
  } = match;

  const queryParam = useQueryString();
  const tier = queryParam.get('tier');

  let history = useHistory();

  if (url.includes('evalmonthly')) {
    history.push(`/${customerid ?? 'error'}/contact-support?error=evalmonthly`);
  }

  const billingInformation = useContext(BillingInformationContext);
  const billingType = getBillingTypeBasedFromUrl(url);

  const stripe = useStripe();
  const elements = useElements();

  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardError, setCardError] = useState();


  async function createCustomer(paymentMethod, cardholderEmail, stripeAccount, customerid) {
    const getStripeUrl = () => {
      switch (stripeAccount) {
        case 'Expressable SLP (CA)':
          return 'stripe-ca';
        case 'Expressable INC (TX)':
          return 'stripe-tx';
        case 'Expressable TPC':
          return 'stripe-tpc';
        case 'Expressable TNJ':
          return 'stripe-tnj';
        case 'Expressable STC':
          return 'stripe-stc';
        default:
          return '';
      }
    };

    const stripeUrl = getStripeUrl(stripeAccount);

    let billingIntakeServiceUrl = `${API}/${stripeUrl}`;
    if (tier) {
      billingIntakeServiceUrl = `${billingIntakeServiceUrl}?tier=${tier}`;
    }
    return fetch(billingIntakeServiceUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: cardholderEmail,
        payment_method: paymentMethod,
        customerid: customerid,
        billingType: billingType,
      }),
    })
      .then(response => {
        let jsonResponse = response.json();
        return jsonResponse;
      })
      .then(response => {
        handleResponse(response);
        setIsSubmitting(false);
      })
      .catch(error => {
        console.error('Error: ', error);
        setIsSubmitting(false);
        setCardError(
          "We're sorry, there was a connection issue with our credit card provider. Please contact us at info@expressable.io so we can help.",
        );
      });
  }

  function handleResponse(response) {
    if (
      response.code === 'card_declined' ||
      response.code === 'expired_card' ||
      response.code === 'email_invalid' ||
      response.code === 'incorrect_address' ||
      response.code === 'incorrect_cvc' ||
      response.code === 'incorrect_number' ||
      response.code === 'incorrect_zip' ||
      response.code === 'invalid_cvc' ||
      response.code === 'invalid_expiry_month' ||
      response.code === 'invalid_expiry_year' ||
      response.code === 'invalid_number' ||
      response.code === 'postal_code_invalid' ||
      response.code === 'processing_error' ||
      response.code === 'account_already_exists' ||
      response.code === 'account_country_invalid_address' ||
      response.code === 'account_invalid' ||
      response.code === 'account_number_invalid' ||
      response.code === 'api_key_expired' ||
      response.code === 'balance_insufficient' ||
      response.code === 'card_decline_rate_limit_exceeded' ||
      response.code === 'charge_already_captured' ||
      response.code === 'country_unsupported'
    ) {
      setCardError(response.raw.message);
    } else if (response === 'Subscription created') {
      if (url.includes('ehr/')) {
        if (billingInformation.Item.intake === 'adult') {
          history.push(`/${customerid}/ehr/adult`);
        } else if (billingInformation.Item.intake === 'child') {
          history.push(`/${customerid}/ehr/child`);
        }
      } else {
        history.push(`/${customerid}/terms-and-conditions`);
      }
    } else {
      setCardError(response?.message ? `We're sorry. ${response.message}` : "We're sorry, there was a connection issue with our credit card provider. Please try again.");
    }
  }

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault();

    // Erases the card error message if one was already set.
    setCardError();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Disable purchase button to prevent multiple submits
    setIsSubmitting(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      // This branch of code triggers if the FE Stripe library finds an error
      // with the card before a request to AWS is sent off. Then, we re-enable
      // the submit button and display an error message

      setIsSubmitting(false);

      if (error.message) {
        setCardError(error.message);
      } else {
        setCardError(
          "We're sorry, but there was an error with your card. Please contact us at info@expressable.io so we can help.",
        );
      }

      // Triggers Logrocket error logging
      console.error('stripe.createPaymentMethod error', error);
    } else {
      createCustomer(paymentMethod, billingDetails.email, stripeAccount, customerid);
    }
  };

  return (
    <form className="checkout-form" onSubmit={handleSubmit}>
      <Field
        id="email"
        type="email"
        placeholder="Billing email"
        required
        autoComplete="email"
        value={billingDetails.email}
        onChange={e => {
          setBillingDetails({ ...billingDetails, email: e.target.value });
        }}
      />
      <div className="w-full">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#9d9d9e',
                  fontWeight: 'light',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>

      {cardError && <CardError error={cardError} />}

      <div className="mt-16 mb-16 flex justify-center">
        <button type="submit" disabled={isSubmitting}>
          Purchase
        </button>
      </div>
    </form>
  );
};

const InjectedCheckoutForm = ({ match, stripeAccount }) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm
          elements={elements}
          stripe={stripe}
          match={match}
          stripeAccount={stripeAccount}
        />
      )}
    </ElementsConsumer>
  );
};

const Field = ({ label, id, type, placeholder, required, autoComplete, value, onChange }) => (
  <div className="FormRow mb-14">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="focus:border-black-600 h-12 border-1 border-black-300 rounded-lg p-3 outline-none w-full"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default InjectedCheckoutForm;