import React, { useEffect, createContext } from 'react';
import Logo from '../header/Logo';
import Header from '../header/Header';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCheckoutForm from '../stripe/MyCheckoutForm';
import {
  CA_STRIPE_KEY,
  TX_STRIPE_KEY,
  TPC_STRIPE_KEY,
  TNJ_STRIPE_KEY,
  STC_STRIPE_KEY,
} from '../../config/stripe/PublishableKeys';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useHistory } from 'react-router-dom';
import { useQueryString } from '../utilities/useQuery';
import priceUtils, { DEFAULT_DEPOSIT_PRICE, DEFAULT_EVAL_PRICE } from '../../utils/price';

dayjs.extend(isSameOrAfter);
// TODO: This should likely be moved to App to prevent re-renders
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromiseCA = loadStripe(CA_STRIPE_KEY);
const stripePromiseTX = loadStripe(TX_STRIPE_KEY);
const stripePromiseTPC = loadStripe(TPC_STRIPE_KEY);
const stripePromiseTNJ = loadStripe(TNJ_STRIPE_KEY);
const stripePromiseSTC = loadStripe(STC_STRIPE_KEY);
const ALLOWED_STRIPE_ACCOUNT_SYMBOLS = ['inc', 'slp', 'tpc', 'tnj', 'stc'];

const getChargesText = (plan = '', price) => {
  const formattedPrice = priceUtils.toCurrencyFormat(price);

  if (plan === 'eval') {
    return {
      chargeText: `After completing your payment information, you’ll be charged ${formattedPrice} for the initial evaluation. You'll be charged for ongoing sessions the day of your appointment.`,
      cancelText: `If you need to cancel or reschedule an appointment, we ask for a 24 hour notice to be eligible to reschedule at a later date.`,
    };
  } else if (plan === 'deposit') {
    return {
      chargeText: `After completing your payment information, your card will be charged ${formattedPrice} to verify your credit card.`,
      cancelText: `If you need to cancel or reschedule an appointment, we ask for a 24 hour notice to be eligible to reschedule at a later date.`,
    };
  }
  return {
    chargeText: `After completing your payment information, you’ll be charged ${formattedPrice} for the initial evaluation. Your weekly
              subscription will then begin automatically for your next scheduled session, and you'll be charged at the
              same time each week moving forward.`,
    cancelText: ` If you need to cancel or reschedule an appointment, we ask for a 24 hour notice to be eligible to
              reschedule at a later date. Because it’s a subscription, the charge will still occur on its usual weekly
              day, but we will provide a make up session.`,
  };
};

export const BillingInformationContext = createContext({});

function BillingAutogenerated({ match }) {
  const { url } = match;


  return <BillingForm match={match} url={url} />;
}

const BillingForm = ({ match }) => {
  const {
    params: { customerid, plan, account },
    url,
  } = match;

  let history = useHistory();
  const queryParam = useQueryString();
  const tier = queryParam.get('tier');


  useEffect(() => {
    // if there is not a valid stripe account or valid plan then redirect to contact support
    const isValidStripeAccount = ALLOWED_STRIPE_ACCOUNT_SYMBOLS.includes(account);

    // monthly evaluation is no longer available
    if (plan === 'evalmonthly') {
      history.push(`/${customerid ?? 'error'}/contact-support?error=evalmonthly`);
    }

    const isValidPlan = plan === 'eval' || plan === 'deposit';

    if (!customerid || !isValidStripeAccount || !isValidPlan) {
      history.push(`/${customerid ?? 'error'}/contact-support`);
    }
    return;
  }, [customerid, url, history, plan, account]);

  const isDeposit = plan === 'deposit';
  const price = isDeposit ? DEFAULT_DEPOSIT_PRICE : priceUtils.getPriceTier(tier) || DEFAULT_EVAL_PRICE;

  const chargesText = getChargesText(plan, price);

  return (
    <>
      <Logo />
      <Header title="Billing" />
      <>
        <section className="max-w-md mx-auto">
          <h2 className="text-2xl font-semibold  flex justify-center">{priceUtils.toCurrencyFormat(price)}</h2>
          {/* Removes the time portion which causes the date to be incorrectly shown typically on pacific time zones */}
          {/* This section only shows if there is an eval date */}
        </section>

        {account === 'slp' && (
          <Elements stripe={stripePromiseCA} className="max-w-md mx-auto px-4">
            <InjectedCheckoutForm
              className="border-red-500"
              match={match}
              stripeAccount={'Expressable SLP (CA)'}
            />
          </Elements>
        )}

        {account === 'inc' && (
          <Elements stripe={stripePromiseTX} className="max-w-md mx-auto px-4">
            <InjectedCheckoutForm
              className="border-red-500"
              match={match}
              stripeAccount={'Expressable INC (TX)'}
            />
          </Elements>
        )}

        {account === 'tpc' && (
          <Elements stripe={stripePromiseTPC} className="max-w-md mx-auto px-4">
            <InjectedCheckoutForm
              className="border-red-500"
              match={match}
              stripeAccount={'Expressable TPC'}
            />
          </Elements>
        )}

        {account === 'tnj' && (
          <Elements stripe={stripePromiseTNJ} className="max-w-md mx-auto px-4">
            <InjectedCheckoutForm
              className="border-red-500"
              match={match}
              stripeAccount={'Expressable TNJ'}
            />
          </Elements>
        )}

        {account === 'stc' && (
          <Elements stripe={stripePromiseSTC} className="max-w-md mx-auto px-4">
            <InjectedCheckoutForm
              className="border-red-500"
              match={match}
              stripeAccount={'Expressable STC'}
            />
          </Elements>
        )}

        <section>
          <p className="text-sm font-medium max-w-md mx-auto px-4 mt-4">{chargesText.chargeText}</p>
          <p className="text-sm max-w-md font-medium mx-auto px-4 mt-4 mb-12">{chargesText.cancelText}</p>
        </section>
      </>
    </>
  );
};

export default BillingAutogenerated;