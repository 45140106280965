import React from "react";
import Logo from '../header/Logo';
import { Link } from 'react-router-dom';

function Welcome({match}) {
  const { params: { customerid } } = match;

  return (
    <div className="font-sans">

    <Logo />

    <section>
      <div className="relative bg-indigo-100 overflow-hidden">
        <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
          <div className="relative h-full max-w-screen-xl mx-auto">
            <svg className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-indigo-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" className="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-indigo-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
            </svg>
          </div>
        </div>

        <div x-data="{ open: false }" className="relative">
          <div className="max-w-xl mx-auto px-4 lg:max-w-screen-xl py-12 flex">
            <div className="">
              <img src="https://ik.imagekit.io/expressable/erica-wood_5_xq892ZG.jpeg?tr=w-500,h-500" alt="{{ therapist }}"
              className="rounded-full w-24 md:w-64 border-4 md:border-8 border-white shadow-lg" />
            </div>
            
            <div className="pl-4 md:pl-6 lg:pl-12 my-auto">
              <h1 className="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl lg:text-5xl mb-2">
                Welcome to Expressable! 
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="max-w-2xl mx-auto mt-16 text-lg px-4 sm:px-6">
      <h2 className="text-2xl font-semibold mb-6">Hi, {customerid}</h2>
      <p>I’m Samantha Smith, your new speech therapist. We’re thrilled that you’ve decided to join us! At Expressable, we’re committed to providing an exceptional speech therapy experience for you and your family.  We couldn’t be more excited to begin helping you reach your communication goals.</p>
      <p className="mt-6 mb-8">Before we begin, we ask that you take a few moments to complete billing and fill out some simple information. This will help me better understand your needs and come prepared starting day one.</p>
      <Link 
        to={{ pathname: `/${customerid}/billing/` }} 
        className="bg-yellow-400 hover:bg-yellow-300 text-black shadow-xl uppercase font-semibold tracking-wide py-4 px-4 rounded-lg block sm:inline-block text-center">Let's Get Started
      </Link>
    </section>
    </div>
  );
}

export default Welcome;