function getPrefix() {
  return process.env.REACT_APP_BILLING_INTAKE_URL;
}

export function getBaseUrl() {
  return process.env.REACT_APP_HEALTH_RECORD_API_URL;
}

const API = getPrefix();

export default API;
