import React from 'react';
import Logo from '../header/Logo';
import StickyButton from '../buttons/StickyButton';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import API from '../../config/apis/APIs';

function TermsAndConditions({ match }) {
  const {
    params: { customerid },
  } = match;
  let history = useHistory();

  function handleTermsAcceptance() {
    axios
      .patch(`${API}/billing-information/${customerid}`, {
        policiesAccepted: true,
      })
      .then(function () {
        history.push(`/${customerid}/informed-consent/`);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      <Logo />
      <TermsAndConditionsContent />
      <StickyButton action={handleTermsAcceptance} />
    </>
  );
}

function TermsAndConditionsContent() {
  return (
    <div className="pt-12 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 max-w-4xl mx-auto font-medium">
      <h1 className="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl lg:text-4xl">
        Terms and Conditions
      </h1>
      <div className="max-w-4xl mx-auto">
        <section>
          <p className="mt-4 font-semibold mb-10 md:mb-16">
            <span className="font-semibold">Effective Date:</span> February 20, 2022
          </p>
          <p className="mt-4">
            These Speech Therapy Terms of Service (“Speech Therapy Terms”) govern your access to, receipt of and use of
            the speech therapy services, including teletherapy services (“Speech Therapy Services”), provided by
            Expressable, Inc., Expressable Speech-Language Pathology, P.C. and our affiliated speech therapy service
            providers (collectively “Expressable”, “we”, “our” or “us”).
          </p>
          <p className="mt-4">
            Speech Therapy Services are currently only available to individuals located in certain states. This list is
            subject to change without advance notice. Expressable, Inc. does not provide Speech Therapy Services in
            states where the corporate practice of speech-language pathology is prohibited.
          </p>
          <p className="mt-4">
            Please read these Speech Therapy Terms carefully before using the Speech Therapy Services. By using the
            Speech Therapy Services, you agree to be bound by these Terms. If you are accepting these Speech Therapy
            Terms on behalf of another person (“Family Member”) as the parent of a minor Family Member or the Family
            Member’s guardian, conservator or custodian, you agree to these Speech Therapy Terms on behalf of such
            Family Member.
          </p>
          <p className="mt-4 font-semibold">
            By accepting these Speech Therapy Terms, you give your informed consent to participate in Speech Therapy
            Services, including telehealth speech therapy sessions.
          </p>
          <p className="mt-4 font-semibold">
            EXPRESSABLE DOES NOT PROVIDE EMERGENCY CARE. IF YOU THINK YOU ARE FACING AN EMERGENCY, PLEASE DO NOT RELY ON
            THE COMPANY FOR HELP. IMMEDIATELY CALL 911 OR GO TO YOUR NEAREST EMERGENCY ROOM.
          </p>
          <p className="mt-4">
            Please refer to our HIPAA Notice of Privacy Practices to learn how Expressable collects, uses, shares and
            protects your Protected Health Information (as defined under the Health Insurance Portability and
            Accountability Act of 1996 or "HIPAA").
          </p>
          <p className="mt-4">
            Your use of the Expressable platform through which Speech Therapy Services are provided is also subject to
            Expressable, Inc.’s Terms of Service and Privacy Policy.
          </p>
          <p className="mt-4">
            We may modify these Speech Therapy Terms from time to time. If we change these Speech Therapy Terms in the
            future, we will update the “Effective Date” to reflect the date of the changes. If we make material changes
            to these Terms, we will make reasonable attempts to notify you, such as by posting a notice on the
            Expressable platform, website or mobile application. If you do not agree with the proposed changes, you
            should discontinue your use of the Speech Therapy Services before the effective date of the change. If you
            continue using the Speech Therapy Services after the effective date, you agree to the modified Speech
            Therapy Terms.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">Financial Responsibility and Assignment of Benefits</h2>
          <p className="mt-4">
            You agree to pay Expressable all applicable charges at the prices then in effect for the Speech Therapy
            Services provided to you or your Family Member. You authorize Expressable to charge your saved payment
            method (your "Payment Method") for the Speech Therapy Services provided to you or your Family Member. If
            your Payment Method is invalid at the time payment is due, you agree to pay all amounts due upon demand. The
            third party services provider who manages your Payment Method may impose terms and conditions on you, which
            are independent of these Speech Therapy Terms, and you agree to comply with all of those terms. Expressable
            may accumulate charges that you’ve incurred for the Speech Therapy Services and submit them as one or more
            aggregate charges during or at the end of each billing cycle. Expressable reserves the right to correct any
            billing errors or mistakes even if payment has already been requested or received.
          </p>
          <p className="mt-4">
            IF YOU REGISTER FOR A MONTHLY MEMBERSHIP, YOU ACKNOWLEDGE AND AGREE THAT (A) EXPRESSABLE (OR OUR THIRD-PARTY
            PAYMENT PROCESSOR) IS AUTHORIZED TO CHARGE YOU ON A MONTHLY BASIS FOR YOUR MEMBERSHIP (IN ADDITION TO ANY
            APPLICABLE TAXES AND OTHER CHARGES) FOR AS LONG AS YOUR MEMBERSHIP CONTINUES, AND (B) YOUR MEMBERSHIP IS
            CONTINUOUS UNTIL YOU CANCEL IT OR WE SUSPEND OR STOP PROVIDING ACCESS TO THE SERVICES IN ACCORDANCE WITH
            THESE SPEECH THERAPY TERMS. YOU MAY CANCEL YOUR MEMBERSHIP AT ANY TIME.
          </p>
          <p className="mt-4">
            If you provide information about your health insurance or health plan, that will be deemed your
            authorization for us to submit claims for covered Speech Therapy Services to your health insurer or health
            plan. You hereby assign or otherwise authorize payment of medical benefits to Expressable for the Speech
            Therapy Services provided to you or your Family Member. You authorize the release of any health or other
            information necessary to process any claims for the Speech Therapy Services. You further understand and
            accept your financial responsibility for any portion of the bill not covered by your health insurer or
            health plan. SUBMISSION OF CHARGES DOES NOT WAIVE OUR RIGHT TO SEEK PAYMENT DIRECTLY FROM YOU.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">Service Termination</h2>
          <p className="mt-4">
            You may terminate your use of the Speech Therapy Services at any time by not using the Speech Therapy
            Services any more. We may terminate your use of the Speech Therapy Services at any time in our reasonable
            discretion, for causes including but not limited illegal conduct, abusive and threatening behavior, and
            continued refusal to pay for our services. We may terminate your use of the Speech Therapy Services by
            sending notice to you at the mail or email address you provided to us or by otherwise contacting you.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">Consent to Electronic Communications</h2>
          <p className="mt-4">
            You agree that Expressable may send the following to you by email or by posting them on our website and
            mobile application: legal disclosures; these Speech Therapy Terms, Notice of Privacy Practices; future
            changes to any of the above; and other notices, policies, communications or disclosures and information
            related to the Speech Therapy Services. You agree that Expressable may contact you via secure messaging,
            email, phone, text, or mail regarding the Speech Therapy Services. You consent to receive such
            communications electronically. You agree to update your contact information to ensure accuracy
          </p>
          <p className="mt-4">
            If you later decide that you do not want to receive certain future communications electronically, please
            send an email to info@expressable.io or a letter to Expressable, 7600 Chevy Chase Drive, Suite 300 - #325,
            Austin TX, 78752. You may also opt out of certain electronic communications through your account or by
            following the unsubscribe instructions in any communication you receive from Expressable. For example, you
            may opt out of receiving automated text messages by texting STOP in response to any automated text message.
            Your withdrawal of consent will be effective within a reasonable time after we receive your withdrawal
            notice described above. If you opt out of automated text messages, you acknowledge that will still receive
            non-automated text messages from your speech therapist related to the Speech Therapy Services that you
            receive.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">Disclaimers</h2>
          <p className="mt-4">
            TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, EXCEPT IN CASE OF NEGLIGENCE OR WILLFUL MISCONDUCT, WE AND OUR
            AFFILIATES, PROVIDERS, EMPLOYEES, OFFICERS, DIRECTORS OR AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OR
            DAMAGE, INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM ANYONE'S USE OF OR INABILITY TO USE THE SPEECH
            THERAPY SERVICES.
          </p>
          <p className="mt-4">
            The Speech Therapy Services are intended for use only within the United States and its territories. We make
            no representation that the Speech Therapy Services are appropriate, or are available for use outside the
            U.S. Those who choose to access and use our Speech Therapy Services from outside the U.S. do so on their own
            initiative, at their own risk, and are responsible for compliance with applicable laws.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">Limitation of Liability</h2>
          <p className="mt-4">
            TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL EXPRESSABLE AND OUR AFFILIATES, PROVIDERS,
            EMPLOYEES, OFFICERS, DIRECTORS OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION THOSE RELATING TO LOST
            PROFITS OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES ARISING OUT OF OR IN CONNECTION WITH THE SPEECH
            THERAPY SERVICES OR FROM THE USE OF OR INABILITY TO USE THE SPEECH THERAPY SERVICES, WHETHER BASED ON
            CONTRACT, WARRANTY, PRODUCT LIABILITY, TORT OR OTHER LEGAL THEORY AND EVEN IF WE HAVE BEEN INFORMED OF THE
            POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">General</h2>
          <p className="mt-4">
            We may change, suspend, or discontinue any of the Speech Therapy Services at any time. We will try to give
            you prior notice of any material changes to the Speech Therapy Services. We will not be liable to you or to
            any third party for any modification, suspension or discontinuance of the Speech Therapy Services. These
            Speech Therapy Terms do not confer any third-party beneficiary rights. You may not transfer any of your
            rights or obligations under these Speech Therapy Terms to anyone else without our consent. Expressable may
            assign our rights in connection with a merger, acquisition, or sale of assets, or by operation of law or
            otherwise. Even after termination, these Speech Therapy Terms will remain in effect such that all terms that
            by their nature may survive termination will survive such termination.
          </p>
        </section>

        <section>
          <h2 className="mt-12 font-semibold text-xl">Contact Us</h2>
          <p className="mt-4">
            If you have any questions or comments about these Speech Therapy Terms, please contact us at
            info@expressable.io.
          </p>
        </section>
      </div>
    </div>
  );
}

export default TermsAndConditions;
