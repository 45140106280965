import React from 'react';

function PrimaryButton({ action, text }) {
  return (
    <button className="button-primary w-full" onClick={action}>
      {text}
    </button>
  );
}

export default PrimaryButton;
