import React from 'react';

function Header({ title }) {
  return (
    <section>
      <div className="mb-4">
        <div className="max-w-xl mx-auto px-4 lg:max-w-screen-xl pb-1 flex">
          <div className="max-w-2xl mx-auto">
            <h1 className="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none ">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
