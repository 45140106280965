import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Welcome from './components/pages/Welcome';
import Billing from './components/pages/Billing';
import TermsAndConditions from './components/pages/TermsAndConditions';
import InformedConsent from './components/pages/InformedConsent';
import PracticePolicies from './components/pages/PracticePolicies';
import ThankYou from './components/pages/ThankYou';
import ScrollToTop from './components/utilities/ScrollToTop';
import ContactSupport from './components/pages/ContactSupport';
import LogRocket from 'logrocket';
import BillingAutogenerated from './components/pages/BillingAutogenerated';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  LogRocket.init('m7fsxw/expressable-billingintake', {
    shouldCaptureIP: false,
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
    network: {
      responseSanitizer: response => {
        response.body = null;
        return response;
      },
      requestSanitizer: request => {
        request.body = null;
        return request;
      },
    },
  });
}

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <ScrollToTop />
          <Route path="/:customerid/welcome" exact component={Welcome} />
          <Route path="/:customerid/billing" exact component={Billing} />
          <Route path="/:account/:customerid/:plan" exact component={BillingAutogenerated} />
          <Route path="/:customerid/ehr/billing" exact component={Billing} />
          <Route path="/:customerid/terms-and-conditions" exact component={TermsAndConditions} />
          <Route path="/:customerid/informed-consent" exact component={InformedConsent} />
          <Route path="/:customerid/practice-policies" exact component={PracticePolicies} />
          <Route path="/:customerid/thank-you" exact component={ThankYou} />
          <Route path="/:customerid/contact-support" exact component={ContactSupport} />
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
