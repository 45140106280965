import React from 'react';
import Logo from '../header/Logo';
import StickyButton from '../buttons/StickyButton';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import API from '../../config/apis/APIs';

const InformedConsent = ({ match }) => {
  const {
    params: { customerid },
  } = match;
  let history = useHistory();

  function handleConsentAcceptance() {
    axios
      .patch(`${API}/billing-information/${customerid}`, {
        informConsent: true,
      })
      .then(function () {
        history.push(`/${customerid}/practice-policies/`);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  return (
    <>
      <Logo />
      <InformedConsentContent />
      <StickyButton action={handleConsentAcceptance} />
    </>
  );
};

const InformedConsentContent = () => {
  return (
    <div className="pt-12 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 max-w-4xl mx-auto">
      <h1 className="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-3xl sm:leading-none md:text-4xl lg:text-4xl mb-10 md:mb-16">
        Informed Consent
      </h1>

      <div className="max-w-4xl mx-auto">
        <section>
          <p className="mt-4 font-medium">
            We are providing this information on behalf of Expressable speech therapists:
          </p>
          <p className="mt-4">
            Telehealth involves the use of communications to enable speech therapists at sites remote from patients to
            provide consultative and treatment services. The information may be used for diagnosis, therapy, follow-up
            and/or education, and may include live two-way audio and video and other materials (e.g., medical records,
            data from medical devices).
          </p>
          <p className="mt-4">
            The communications systems used will incorporate reasonable security protocols to protect the
            confidentiality of patient information and will include reasonable measures to safeguard the data and to
            ensure its integrity against intentional or unintentional corruption, including encryption of information
            transmitted through the Expressable platform.
          </p>
          <p className="mt-4">
            You acknowledge that your speech therapists credentials were made available to you before scheduling an
            appointment. If you have any questions about these credentials, please direct them to your speech therapist.
          </p>
          <p className="mt-6 font-medium">Anticipated Benefits of Telehealth:</p>
          <ul class="list-disc ml-4">
            <li class="mt-4">
              Improved access to medical care by enabling a patient to remain at his or her home or office while
              consulting a speech therapist.
            </li>
            <li class="mt-4">More efficient speech therapy evaluation and management.</li>
          </ul>

          <p className="mt-8 font-medium">Possible Risks of Telehealth:</p>
          <p className="mt-4">
            As with any health care service, there are potential risks associated with the use of telemedicine.
            Expressable believes that the likelihood of these risks materializing is very low. These risks may include,
            without limitation, the following:
          </p>
          <ul class="list-disc ml-4">
            <li class="mt-4">
              Delays in evaluation and consultation or treatment may occur due to deficiencies or failures of the
              equipment.
            </li>
            <li class="mt-4">Security protocols could fail, causing a breach of privacy of your health information.</li>
            <li class="mt-4">
              Information you transmit through the Expressable platform may be insufficient to allow for appropriate
              decision-making by your therapist.
            </li>
          </ul>

          <p className="mt-4 font-medium">
            By accepting these Terms, you acknowledge that you understand and agree with the following:
          </p>
          <ul class="list-disc ml-4">
            <li class="mt-4">
              The Laws that protect privacy and the confidentiality of health information also apply to telehealth; I
              have received Expressable’s Notice of Privacy Practices, which explains how Expressable collects, uses,
              shares and protects your Protected Health Information in greater detail.
            </li>
            <li class="mt-4">
              Telehealth may involve electronic communication of my health information to therapists who may be located
              in other areas, including out of state.
            </li>
            <li class="mt-4">
              You may expect the anticipated benefits from the use of telehealth in your care, but no results can be or
              are guaranteed or assured by Expressable.
            </li>
            <li class="mt-4">
              Your therapist will decide, in his or her sole discretion, whether it is appropriate to treat your
              condition using telehealth and the Expressable platform. Your therapist may request that you halt
              receiving telehealth services and instead receive in-person care if deemed appropriate.{' '}
            </li>
            <li class="mt-4">
              Your healthcare information may be shared with others (including health care providers and health
              insurers) for treatment, payment, and healthcare operations purposes. In addition, your healthcare
              information may be shared in the following circumstances:
            </li>
            <ul class="mt-2 list-disc ml-4">
              <li>When a valid court order is issued for medical records.</li>
              <li>Reporting suspected abuse, neglect, or domestic violence.</li>
              <li>Preventing or reducing a serious threat to anyone’s health or safety.</li>
              <li>As otherwise permitted by applicable Law.</li>
            </ul>
            <li class="mt-4">
              You will hold Expressable harmless for any information lost due to technical failures of the Expressable
              platform when receiving telehealth services
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default InformedConsent;
