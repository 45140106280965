import React from "react";
import { useQueryString } from '../utilities/useQuery';
import Logo from '../header/Logo';
import Header from '../header/Header';
import LogRocket from "logrocket";

function ContactSupport() {
  const queryParam = useQueryString();
  const error = queryParam.get('error');

  return (
    <>
      <Logo />
      <Header title="Please Contact Support" />
      <div className="pt-12 pb-20 px-6 sm:px-6 lg:pt-12 lg:pb-28 max-w-4xl mx-auto">
        <div className="max-w-4xl mx-auto">

          <section>
            <p className="mt-4">We're sorry, but there was an error. Please contact us at (512) 768-0319 or email us at help@expressable.io and we'll help to resolve this as soon as possible.</p>
          </section>

          {error === 'evalmonthly' &&
            <section>
              <p className="mt-4">Error detail: Monthly evaluation is no longer available.</p>
            </section>
          }

          {LogRocket.error('Contact Support page reached')}
          {error && LogRocket.error('Error detail: Monthly evaluation is no longer available.')}

        </div>
      </div>
    </>
  );
}

export default ContactSupport;
