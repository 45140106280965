import React from "react";
import logo from '../../expressable-logo.svg';

function Logo() {
  return (
    <div className="mt-6 mb-16 px-6 flex items-center justify-center">
      <img src={logo} alt="Expressable Logo"/>
    </div>
  );
}

export default Logo;