import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';

function StickyButton({ action }) {
  return (
    <div className="fixed inset-x-0 bottom-0 shadow-lg border-gray-200 border-t-2">
      <div className="bg-caramel-400">
        <div className="max-w-4xl mx-auto py-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex-shrink-0 w-full sm:w-auto px-4 md:px-2">
              <PrimaryButton text="I Agree" action={action} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickyButton;
